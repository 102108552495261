@font-face {
  font-family: 'Verdana';
  src: url("../assets/fonts/Verdana/verdana.ttf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: 'Verdana';
  src: url("../assets/fonts/Verdana/verdana-bold.ttf") format("opentype");
  font-weight: 700;
}
