@import "font";
@import "header";
@import "default";

.wrapper {
  padding: 70px 0 0;
  .swiper1 {
    width: 100%;
    height: 700px;
    @media (max-width: 769px) {
      height: 500px;
    }
    .swiper-wrapper {
      .swiper-slide {
        position: relative;
        font-family: 'Kdam Thmor Pro', sans-serif;
        &:before {
          background: rgba(0, 0, 0, 0.35);
          content: "";
          position: absolute;
          width: 100%;
          height: 700px;
          @media (max-width: 769px) {
            height: 500px;
          }
        }
        &:nth-child(odd) {
          .slide-text {
            font-size: 62px;
            color: #fff;
            position: absolute;
            left: 5%;
            top: 15%;
            @media (max-width: 769px) {
              font-size: 45px;
            }
          }
        }
        &:nth-child(even) {
          .slide-text {
            font-size: 62px;
            position: absolute;
            right: 0;
            top: 15%;
            color: #fff;
            @media (max-width: 769px) {
              font-size: 45px;
            }
          }
        }
        &:nth-child(3) {
          .slide-text {
            font-size: 52px;
            position: absolute;
            left: initial;
            right: 5%!important;
            color: #fff;
            top: 15%;
            @media (max-width: 769px) {
              font-size: 40px;
            }
          }
        }
        &:nth-child(2) {
          .slide-text {
            left: 5%;
            @media (max-width: 769px) {
              font-size: 45px;
            }
          }
        }
        .slide-text {
          width: 40%;
          @media (max-width: 1025px) {
            width: 60%;
          }
          @media (max-width: 630px) {
            width: 70%;
          }
        }
        img {
          width: 100%;
          height: 700px;
          object-fit: cover;
        }
      }
    }
    .swiper-pagination {
      width: 30% !important;
      left: initial!important;
      right: 0;
      @media (max-width: 769px) {
        width: 100%!important;
      }
      &-bullet {
        width: 40px;
        border: 1px solid #fff;
        height: 4px;
        border-radius: 10px;
        &-active {
          background-color: #018801;
        }
      }
    }
  }
  .future-section {
    background-image: url("../images/future-section.png");
    background-size: 100% 100%;
    height: 800px;
    background-repeat: no-repeat;
    object-fit: cover;
    @media (min-width: 1450px) {
      height: 850px;
    }
    @media (min-width: 1800px) {
      height: 1000px;
    }
    @media (max-width: 1350px) {
      background-image: none;
      height: initial;
    }
    .wrapper {
      padding: 30px 60px;
      justify-content: space-between;
      position: relative;
      flex-wrap: wrap;
      display: none;
      @media (max-width: 1350px) {
        display: flex;
      }
      @media (max-width: 430px) {
        padding: 30px 30px;
      }
      .line {
        position: absolute;
        height: 20px;
        width: 40%;
        display: none;
        background-color: #018801;
        top: 20%;
        z-index: 2;
        &:nth-child(2) {
          height: 40px;
          top: 30%;
          width: 20%;
        }
        &:nth-child(3) {
          top: 60%;
          width: 60%;
        }
        &:nth-child(4) {
          right: 0;
          top: 10%;
        }
        &:nth-child(5) {
          right: 0;
          top: 40%;
          width: 30%;
        }
        &:nth-child(6) {
          right: 0;
          top: 70%;
          height: 40px;
          width: 25%;
        }
      }
      img {
        width: 200px;
        height: 400px;
        position: relative;
        z-index: 3;
        @media (max-width: 1341px) {
          display: block;
          margin: 0 auto;
        }
        &:nth-child(2n) {
          width: 150px;
        }
        &:nth-child(2n+1) {
          margin-top: 5em;
          @media (max-width: 1341px) {
            margin: 0 auto;
          }
        }
        &:nth-child(4) {
          margin-top: 2em;
          @media (max-width: 1341px) {
            margin: 0 auto;
          }
        }
        &:nth-child(5) {
          margin-top: 15em;
          width: 120px;
          height: 240px;
          @media (max-width: 1341px) {
            margin: 0 auto;
          }
        }
        &:nth-child(6n) {
          width: 300px;
        }
      }
      .future-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5em;
        font-size: 26px;
        width: 200px;
        @media (max-width: 1341px) {
          margin: 0 auto;
        }
        img {
          margin-top: 3em;
          width: 120px;
          height: 240px;
          @media (max-width: 1341px) {
            margin: 0 auto;
          }
        }
      }
    }
  }
  .parallax-section {
    background-image: url("../images/parallax-image.jpg");
    min-height: 800px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 70% 100%;
    position: relative;
    @media (max-width: 800px) {
      background-size: cover;
    }
    &:before {
      content: "";
      position: absolute;
      right: 0;
      width: 30%;
      height: 100%;
      background: linear-gradient(to right, #010101, #000);
      @media (max-width: 800px) {
        display: none;
      }
    }
    .wrapper {
      display: flex;
      justify-content: flex-end;
      padding: 60px;
      @media (max-width: 430px) {
        padding: 30px 30px;
      }
      .parallax-blocks {
        width: 40%;
        position: relative;
        @media (max-width: 999px) {
          width: 60%;
        }
        @media (max-width: 776px) {
          width: 80%;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
        &__block {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          z-index: 2;
          text-decoration: none;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            color: #fff;
            text-align: center;
            width: 50%;
            transition: color .5s;
          }
          img {
            width: 50%;
          }
          &:hover {
            span {
              color: #018801;
            }
            img {
            }
          }
        }
      }
    }
  }
  .news-section {
    background-color: #000;
    padding: 60px 60px 120px;
    &__header {
      color: #fff;
      font-family: 'Kdam Thmor Pro', sans-serif;
      font-size: 62px;
      padding-bottom: 60px;
    }
    .swiper2 {
      width: 100%;
      .swiper-wrapper {
        .swiper-slide {
          &__inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #fff;
            border-radius: 20px;
            padding: 20px 30px;
            height: 550px;
            color: #333;
            div {
              img {
                width: 100%;
                height: 300px;
                margin-bottom: 20px;
                object-fit: cover;
              }
              span {
                text-align: left;
              }
            }
            a {
              color: #333;
              text-decoration: none;
            }
          }
        }
      }
      .swiper-button-next,
      .swiper-button-prev {
        color: #018801;
      }
      .swiper-button-prev {
        left: 1px;
      }
      .swiper-button-next {
        right: 1px;
      }
    }
  }
}
@-webkit-keyframes grayscale {
  0%  { -webkit-filter: grayscale(100%); }
  50% { -webkit-filter: grayscale(50%); }
  100% { -webkit-filter: grayscale(0); }
}
@-webkit-keyframes brightness {
  0%  { -webkit-filter: brightness(70%); }
  50% { -webkit-filter: brightness(35%); }
  100% { -webkit-filter: brightness(0); }
}