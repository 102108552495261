html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Verdana";
}
h1, h2, h3, h4, h5, h6, p {
  margin-block-start: 0;
  margin-block-end: 0;
}
ul, li {
  padding-inline-start: 0;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
