.scroll-up {
  background-color: rgba(238,238,238,1);
  top: 0;
}
.scroll-down {
  top: -71px;
  @media(max-width: 1025px) {
    top: -109px;
  }
  @media (max-width: 999px) {
    top: -71px;
  }
}

header {
  top: 0;
  position: fixed;
  transition: all .4s;
  background-color: rgba(238,238,238,1);
  width: 100%;
  z-index: 100;
  .wrapper {
    display: flex;
    align-items: center;
    padding: 0 60px;
    @media (max-width: 999px) {
      justify-content: space-between;
      padding: 7px 60px;
    }
    a {
      color: #000;
      text-decoration: none;
    }
    .logo {
      width: 15%;
      img {
        width: 80px;
      }
    }
    .wrap-menu-icon {
      display: none;
      @media (max-width: 999px) {
        display: block;
      }
      .menu-icon {
        width: 30px;
        display: block;
        span {
          width: 100%;
          height: 2px;
          margin-bottom: 7px;
          display: block;
          border-radius: 3px;
          background-color: #018801;
        }
      }
    }
    .menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85%;
      transition: all .4s;
      @media (max-width: 1025px) {
        flex-direction: column;
        align-items: flex-start;
      }
      @media (max-width: 999px) {
        position: absolute;
        right: 0;
        top: 0;
        width: 250px;
        opacity: 0;
        visibility: hidden;
        z-index: -10;
      }
      .menu-buttons {
        @media(max-width: 1025px) {
          padding: 0 0 20px;
        }
        @media(max-width: 999px) {
          display: flex;
          flex-direction: column;
          width: 75%;
          align-items: center;
        }
        .contact-button {
          padding: 5px 25px;
          border-radius: 4px;
          background-color: #fff;
          transition: all .5s;
          @media (max-width: 999px) {
            margin-bottom: 10px;
          }
          &:hover {
            background-color: #018801;
            color: #fff;
          }
        }
        .language {
          margin-left: 30px;
          padding: .5em;
          width: 75px;
          border-radius: .2em;
          border: 1px solid #acacac;
          color: #181820;
          appearance: none;
          background: url('https://cdn1.iconfinder.com/data/icons/arrows-vol-1-4/24/dropdown_arrow-512.png');
          background-repeat: no-repeat;
          background-size: 15px 15px;
          background-position: right;
          background-origin: content-box;
          span {
            font-size: 20px;
          }
          @media (max-width: 999px) {
            margin-left: 0;
          }
        }
      }
      ul {
        list-style: none;
        display: flex;
        @media(max-width: 999px) {
          flex-direction: column;
          width: 75%;
          align-items: center;
        }
        li {
          margin-right: 6em;
          padding: 10px 15px;
          @media (max-width: 999px) {
            margin-right: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          a {
            color: #000;
            position: relative;
            transition: color .3s;
            &:before {
              content: "";
              position: absolute;
              height: 3px;
              width: 0;
              left: 0;
              background-color: #018801;
              border-radius: 3px;
              -webkit-transition: width .3s;
              -moz-transition: width .3s;
              -o-transition: width .3s;
              transition: width .3s;
              bottom: -3px;
            }
            &:hover {
              color: #018801;
              &:before {
                width: 100%;
              }
            }
          }
        }
        .dropdown {
          display: flex;
          position: relative;
          .sub-menu {
            display: none;
            background-color: rgba(238,238,238,1);
            width: 270px;
            position: absolute;
            bottom: -95px;
            left: -80px;
            padding-bottom: 5px;
            z-index: 99;
            ul {
              flex-direction: column;
              align-items: center;
              width: 50%;
              li {
                padding: 5px 0 0 0;
                margin-right: 0;
                span {
                  color: #018801;
                }
              }
            }
          }
          &:hover {
            .sub-menu {
              display: flex;
            }
          }
        }
      }
      .menu-icon-close {
        width: 30px;
        display: none;
        position: absolute;
        right: 30px;
        top: 34px;
        @media (max-width: 999px) {
          display: block;
        }
        span {
          width: 100%;
          transform: rotate(45deg);
          height: 2px;
          margin-bottom: 7px;
          display: block;
          border-radius: 3px;
          background-color: #018801;
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            margin-bottom: 0;
            margin-top: -9px;
          }
        }
      }
    }
    .active {
      opacity: 1;
      background-color: #eee;
      visibility: visible;
      z-index: 5;
    }
  }
}